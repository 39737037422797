<template>
  <div class="container">
    <div class="order-lg-1">
      <div class="card mb-4 shadow-sm">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">{{ $t('contact.title') }}</h4>
        </div>
        <div class="card-body">
          <div class="text-left">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{ $t('contact.firstName') }}:</label>
              <label class="col-sm-8 col-form-label">{{customer.contactFirstName}}</label>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{ $t('contact.lastName') }}:</label>
              <label class="col-sm-8 col-form-label">{{customer.contactLastName}}</label>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{ $t('contact.email') }}:</label>
              <label class="col-sm-8 col-form-label">{{customer.contactEmail}}</label>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{ $t('contact.phoneNumber') }}:</label>
              <label class="col-sm-8 col-form-label">{{customer.contactPhone}}</label>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{ $t('address.address') }}:</label>
              <label
                class="col-sm-8 col-form-label"
              >{{customer.contactAddress}}, {{customer.contactCity}}, {{customer.contactState}}, {{customer.contactCountry}}, {{customer.contactZip}}</label>
            </div>
          </div>
          <div class="row justify-content-around" v-if="editBtn == 'true'">
            <div class="col-lg-4 mb-3 text-center">
              <button
                type="button"
                class="btn btn-lg btn-primary edit"
                v-on:click="$emit('edit', 'editContactInf')"
              >{{ $t('pagination.edit') }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-4 shadow-sm">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">{{ $t('company.title') }}</h4>
        </div>
        <div class="card-body">
          <div class="text-left">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{ $t('company.companyName') }}:</label>
              <label
                class="col-sm-8 col-form-label"
              >{{customer.companyName}} {{customer.designator}}</label>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{ $t('company.industry') }}:</label>
              <label
                class="col-sm-8 col-form-label"
                v-if="customer.industry != 'Others'"
              >{{ $t(customer.industry) }}</label>
              <label class="col-sm-8 col-form-label" v-else>{{customer.industryOther}}</label>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{ $t('company.purpose') }}:</label>
              <label class="col-sm-8 col-form-label">{{customer.purpose}}</label>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{ $t('company.companyAddress') }}:</label>
              <label
                class="col-sm-8 col-form-label"
              >{{customer.companyAddress}}, {{customer.companyCity}}, {{customer.companyState}}, {{customer.companyCountry}}, {{customer.companyZip}}</label>
            </div>
            <div v-show="customer.type=='CCORP'">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">{{ $t('company.numberOfDirectors') }}:</label>
                <label class="col-sm-8 col-form-label">{{customer.directorNum}}</label>
              </div>
              <div v-if="customer.directorNum">
                <div
                  class="form-group row"
                  v-for="i in parseInt(customer.directorNum)"
                  v-bind:key="i"
                >
                  <label class="col-sm-4 col-form-label">{{ $t('company.director') }} {{i}}:</label>
                  <label
                    class="col-sm-8 col-form-label"
                  >{{customer['directorFirstName' + i]}} {{customer['directorLastName' + i]}}</label>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">{{ $t('company.numberOfSharesAuthorized') }}:</label>
                <label class="col-sm-8 col-form-label">{{customer.shareNum}}</label>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">{{ $t('company.shareParValue') }}:</label>
                <label class="col-sm-8 col-form-label">{{customer.shareValue}}</label>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">{{ $t('company.numberOfShareholders') }}:</label>
                <label class="col-sm-8 col-form-label">{{customer.shareholder}}</label>
              </div>
            </div>
            <div v-show="customer.type=='LLC'">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">{{ $t('company.numberOfMember') }}:</label>
                <label class="col-sm-8 col-form-label">{{customer.memberNum}}</label>
              </div>
              <div v-if="customer.memberNum">
                <div
                  class="form-group row"
                  v-for="i in parseInt(customer.memberNum)"
                  v-bind:key="i"
                >
                  <label class="col-sm-4 col-form-label">{{ $t('company.member') }} {{i}}:</label>
                  <label
                    class="col-sm-8 col-form-label"
                    v-if="customer['memberisIndividual' + i] == 'true'"
                  >{{customer['memberFirstName' + i]}} {{customer['memberLastName' + i]}}</label>
                  <label
                    class="col-sm-8 col-form-label"
                    v-else
                  >{{customer['memberCompanyName' + i]}} {{customer['memberCompanyRep' + i]}}</label>
                  <label
                    class="col-sm-4 col-form-label"
                  >{{ $t('company.member') }} {{i}} {{ $t('address.address') }}:</label>
                  <label
                    class="col-sm-8 col-form-label"
                  >{{customer['memberAddress' + i]}}, {{customer['memberCity' + i]}}, {{customer['memberState' + i]}}, {{customer['memberCountry' + i]}}, {{customer['memberZip' + i]}}</label>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">{{ $t('company.memberManager') }}:</label>
                <label class="col-sm-8 col-form-label">{{customer.memberOption}}</label>
              </div>
            </div>
            <div v-show="customer.plan == 'PREMIUM'">
              <div class="form-group row" v-show="customer.fictitious">
                <label class="col-sm-4 col-form-label">{{ $t('company.fictitiousName') }}:</label>
                <label class="col-sm-8 col-form-label">{{customer.fictitious}}</label>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">{{ $t('company.businessLocated') }}:</label>
                <label
                  class="col-sm-8 col-form-label"
                >{{customer.businessCounty}} ,{{customer.businessState}}</label>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">{{ $t('company.nameOfResponsibleParty') }}:</label>
                <label
                  class="col-sm-8 col-form-label"
                >{{customer.responsibleFirstName}} {{customer.responsibleLastName}}</label>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">SSN/ITIN:</label>
                <label class="col-sm-8 col-form-label">{{customer.resposibleSSN}}</label>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">{{ $t('company.startedDate') }}:</label>
                <label class="col-sm-8 col-form-label">{{customer.startDate}}</label>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">{{ $t('company.closedMonth') }}:</label>
                <label class="col-sm-8 col-form-label">{{customer.closingMonth}}</label>
              </div>
            </div>
          </div>
          <div class="row justify-content-around" v-if="editBtn == 'true'">
            <div class="col-lg-4 mb-3 text-center">
              <button
                type="button"
                class="btn btn-lg btn-primary edit"
                v-on:click="$emit('edit', 'editCompanyInf')"
              >{{ $t('pagination.edit') }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-4 shadow-sm">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">{{ $t('agent.title') }}</h4>
        </div>
        <div class="card-body">
          <div class="text-left">
            <p>{{ $t('agent.content4') }}</p>
          </div>
        </div>
      </div>
      <div class="card mb-4 shadow-sm" v-if="!shouldHideOptionalService">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">{{ $t('optionalService.title') }}</h4>
        </div>
        <div class="card-body">
          <div class="text-left">
            <div class="form-group row" v-if="customer.annualReport">
              <label class="col-sm-4 col-form-label">{{ $t('optionalService.initial') }}:</label>
              <label class="col-sm-8 col-form-label">
                <div>{{ $t('optionalService.serviceFee') }}: $100</div>
                <div>{{ $t('optionalService.governmentFee') }}: ${{customer.type === 'LLC' ? 20 : 25}}</div>
              </label>
            </div>
            <div class="form-group row" v-if="customer.trademarkRegister">
              <label class="col-sm-4 col-form-label">{{ $t('optionalService.trademark') }}:</label>
              <label class="col-sm-8 col-form-label">
                <div>{{ $t('optionalService.trademarkHint') }}</div>
                <div>{{ $t('optionalService.serviceFee') }}: $500</div>
                <div>{{ $t('optionalService.governmentFee') }}: $275/Category</div>
              </label>
            </div>
          </div>
          <div class="row justify-content-around" v-if="editBtn == 'true'">
            <div class="col-lg-4 mb-3 text-center">
              <button
                type="button"
                class="btn btn-lg btn-primary edit"
                v-on:click="$emit('edit', 'editOptionalService')"
              >{{ $t('pagination.edit') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Summary',
  props: ['customer', 'editBtn'],
  computed: {
    shouldHideOptionalService() {
      return localStorage.getItem('state') === 'DE'
    },
  },
}
</script>

<style scoped>
</style>
