<template>
  <div data-v-3abba179 class="py-5 text-center">
    <h1 data-v-3abba179>{{ $t('Order Review') }}</h1>
    <h3 class="mb-3">{{ $t('for order id:') }} {{this.$route.query.orderId}}</h3>
    <div class="mb-3" v-if="token">
      <button
        type="button"
        class="btn btn-lg btn-primary yellowBtn font-weight-bolder mx-2"
        @click="incorpForm"
      >Artical of Incorpation</button>
      <button
        type="button"
        class="btn btn-lg btn-primary yellowBtn font-weight-bolder mx-2"
        @click="ss4Form"
      >SS4 Form</button>
    </div>
    <div v-if="customer">
      <div class="container">
        <div class="card mb-4 shadow-sm">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">{{ $t('Basic Information') }}</h4>
          </div>
          <div class="card-body">
            <div class="text-left">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">{{ $t('Entity Type:') }}</label>
                <label class="col-sm-8 col-form-label">{{customerObj.type}}</label>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">{{ $t('State of Formation:') }}</label>
                <label
                  class="col-sm-8 col-form-label"
                >{{ $t('message.' + customerObj.state.toLowerCase()) }}</label>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">{{ $t('Package:') }}</label>
                <label class="col-sm-8 col-form-label">{{ $t(customerObj.plan) }}</label>
              </div>
              <div class="form-group row" v-for="item in (customer.itemList)" :key="item.name">
                <label class="col-sm-4 col-form-label">{{ $t(item.name) }}:</label>
                <label class="col-sm-8 col-form-label">$ {{item.totalFee}}</label>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Total Fee (USD):</label>
                <label class="col-sm-8 col-form-label">$ {{customer.totalFee}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Summary v-bind:customer="customerObj" editBtn="false" />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import Summary from '@/components/CheckOut/Summary'

export default {
  name: 'OrderSummary',
  data() {
    return {
      orderId: this.$route.query.orderId,
      token: '',
    }
  },
  components: {
    Summary,
  },
  created: function() {
    if (this.$route.query.token !== undefined) {
      localStorage.token = this.$route.query.token
    }
    if (localStorage.token) {
      this.token = localStorage.token
    }
    window.scrollTo(0, 0)
    this.$root.$i18n.locale = this.$route.query.locale || 'en'
  },
  computed: {
    customerObj: function() {
      return JSON.parse(this.customer.caseInfo)
    },
  },
  methods: {
    incorpForm() {
      var routeData =
        'https://api.corplaw.ai/doc?fname=incorp&token=' +
        localStorage.token +
        '&orderId=' +
        this.$route.query.orderId
      window.open(routeData, '_blank')
    },
    ss4Form() {
      var routeData =
        'https://api.corplaw.ai/doc?fname=ss4&token=' +
        localStorage.token +
        '&orderId=' +
        this.$route.query.orderId
      window.open(routeData, '_blank')
    },
  },

  apollo: {
    customer() {
      return {
        // gql query
        query: gql`
          query customerOrder($orderId: String!) {
            customer: order(orderId: $orderId) {
              itemList {
                name
                totalFee
              }
              caseInfo
              totalFee
            }
          }
        `,
        variables() {
          return {
            orderId: this.orderId,
          }
        },
        skip() {
          return !this.orderId
        },
        fetchPolicy: 'cache-first',
      }
    },
  },
}
</script>

<style scoped>
.yellowBtn {
  color: rgba(54, 77, 110, 1);
  background-color: rgba(242, 204, 79, 1);
  border: none;
}
</style>
